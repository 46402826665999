import React from "react";
import {useSiteMetadata} from "../hooks/use-site-metadata";
import ContactForm from "./contact-form";

export default function Contact() {

    const siteMetadata = useSiteMetadata();

    return (
        <section className="w-full bg-yellow-50">
            <div className="mx-auto max-w-7xl">
                <div className="flex flex-col lg:flex-row">

                    <div className="relative w-full bg-cover lg:w-5/12 xl:w-5/12 bg-gradient-to-r from-yellow-50 via-yellow-50 to-yellow-100">
                        <div className="relative flex flex-col items-center justify-center w-full h-full px-10 my-20 lg:px-16 lg:my-0">
                            <div className="flex flex-col items-start space-y-8 tracking-tight lg:max-w-3xl">
                                <div className="relative">
                                    <p className="mb-2 font-medium text-gray-700 uppercase">We are here to help</p>
                                    <h2 className="text-5xl font-bold text-gray-900 xl:text-6xl">
                                        We'd love to hear from you!
                                    </h2>
                                </div>
                                <p className="text-2xl text-gray-700">
                                    At {siteMetadata.name}, our priority is your satisfaction.
                                    We can help you find the perfect plan for your needs.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="w-full bg-yellow-50 lg:w-7/12 xl:w-7/12">
                        <ContactForm />
                    </div>
                </div>
            </div>

        </section>
    );
}