import React from "react";
import {StaticImage} from "gatsby-plugin-image";
import {useSiteMetadata} from "../hooks/use-site-metadata";

function ContentOverview() {

    const siteMetadata = useSiteMetadata();

    return (
        <section className="py-20 bg-yellow-50">
            <div className="flex flex-col px-8 mx-auto space-y-12 max-w-7xl xl:px-12">

                {/* Intro */}
                <div className="relative">
                    <h2 className="w-full text-3xl font-bold text-center sm:text-4xl md:text-5xl">
                        Transcribing should not be a difficulty, <br/>
                        but an opportunity
                    </h2>
                    <p className="w-full py-8 mx-auto -mt-2 text-lg text-center text-gray-700 intro sm:max-w-3xl">
                        We all know how hard it is to create a transcript. It doesn't need to be. <br/>
                        At {siteMetadata.name}, we help you focus on your audience, not on the process.
                    </p>
                </div>

                {/* Content 1 */}
                <div className="flex flex-col mb-8 animated fadeIn sm:flex-row">
                    <div className="flex items-center mb-8 sm:w-1/2 md:w-5/12 sm:order-last">
                        <StaticImage className="rounded-lg shadow-xl h-full"
                                     src="../images/time-consuming.png"
                                     alt="Time Consuming" />
                    </div>
                    <div className="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pr-16">
                        <p className="mb-2 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">
                            Trained Artificial Intelligence
                        </p>
                        <h3 className="mt-2 text-2xl sm:text-left md:text-4xl">
                            Transcription is time consuming. <br/>
                            Not anymore.
                        </h3>
                        <p className="mt-5 text-lg text-gray-700 text md:text-left">
                            Transcribing your media has never been so fast, with our specially trained robots you will be writing your text in no time.
                        </p>
                    </div>
                </div>

                {/* Content 2 */}
                <div className="flex flex-col mb-8 animated fadeIn sm:flex-row">
                    <div className="flex items-center mb-8 sm:w-1/2 md:w-5/12">
                         <StaticImage className="rounded-lg shadow-xl"
                             src="../images/no-sleep.png" alt="No Sleep" />
                    </div>
                    <div className="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pl-16">
                        <p className="mb-2 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">
                            24x7 Availability
                        </p>
                        <h3 className="mt-2 text-2xl sm:text-left md:text-4xl">
                            Human transcribers need to sleep. <br/>
                            Robots don't.
                        </h3>
                        <p className="mt-5 text-lg text-gray-700 text md:text-left">
                            How much time did you already waste because you can't reach your worker ?<br/>
                            Everyone needs to disconnect and that's normal. We are humans after all.<br/>
                            But Robots are not humans and they are always available for you, by day and night.
                        </p>
                    </div>
                </div>

                {/* Content 3 */}
                <div className="flex flex-col mb-8 animated fadeIn sm:flex-row">
                    <div className="flex items-center mb-8 sm:w-1/2 md:w-5/12 sm:order-last">
                        <StaticImage className="rounded-lg shadow-xl bg-white"
                                     src="../images/misunderstanding2.png" alt="Language Misunderstanding" />
                    </div>
                    <div className="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pr-16">
                        <p className="mb-2 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">
                            Multiple language support
                        </p>
                        <h3 className="mt-2 text-2xl sm:text-left md:text-4xl">
                            Language cannot be exclusive. <br/>
                            Never.
                        </h3>
                        <p className="mt-5 text-lg text-gray-700 text md:text-left">
                            Finding transcriber in your language could be journey. Not everyone realizes it.<br/>
                            We offer several dozen languages, to be as inclusive as possible.
                        </p>
                    </div>
                </div>

                {/* Content 4 */}
                <div className="flex flex-col mb-8 animated fadeIn sm:flex-row">
                    <div className="flex items-center mb-8 sm:w-1/2 md:w-5/12">
                        <StaticImage className="rounded-lg shadow-xl"
                                     src="../images/parties-integration2.png" alt="3rd parties integration" />
                    </div>
                    <div className="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pl-16">
                        <p className="mb-2 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">
                            Integration made easy
                        </p>
                        <h3 className="mt-2 text-2xl sm:text-left md:text-4xl">
                            Do not repeat yourself.<br/>
                            Reuse your published medias.
                        </h3>
                        <p className="mt-5 text-lg text-gray-700 text md:text-left">
                            Because you don't want to loose your time by uploading your large files, again and again.<br/>
                            Let us connect to your media platform and do the work for you.
                        </p>
                    </div>
                </div>

            </div>
        </section>
    );

}

export default ContentOverview;