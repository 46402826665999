import React, {useState} from "react";
import {useSiteMetadata} from "../hooks/use-site-metadata";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";


export default function ContactForm() {

    const siteMetadata = useSiteMetadata();

    const [response, setResponse] = useState({
        type: '',
        message: ''
    });

    async function sendMail(data) {
        try {
            const contact = {
                name: data.lastName,
                $firstName: data.firstName,
                email: data.email,
                subject: `${siteMetadata.name} - StaticForms - Contact Form`,
                honeypot: '',
                message: data.message,
                replyTo: '@',
                accessKey: siteMetadata.staticForms.accessKey
            }
            const res = await fetch('https://api.staticforms.xyz/submit', {
                method: 'POST',
                body: JSON.stringify(contact),
                headers: { 'Content-Type': 'application/json' }
            });

            const json = await res.json();

            if (json.success) {
                setResponse({
                    type: 'success',
                    message: 'Thank you for reaching out to us.'
                });
            } else {
                setResponse({
                    type: 'error',
                    message: json.message
                });
            }
        } catch (e) {
            console.log('An error occurred', e);
            setResponse({
                type: 'error',
                message: 'An error occurred while submitting the form'
            });
        }
    }

    const schema = yup.object().shape({
        firstName: yup.string().label('First name'),
        lastName: yup.string().label('Last name'),
        message: yup.string().required().label('Message'),
        email: yup.string().email().required().label('E-mail')
    });
    const { register, handleSubmit, watch, errors } = useForm({
        resolver: yupResolver(schema),
        mode: 'onBlur'
    });
    const onSubmit = async data => {
        await sendMail(data)
    }

    return (
        <div className="flex flex-col items-start justify-start w-full h-full p-10 lg:p-16 xl:p-24">
            <h4 className="w-full text-3xl font-bold">Contact Us</h4>

            <div className="relative w-full mt-10 space-y-8">
                {/*<form onSubmit={handleSubmit}>*/}
                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label className="block uppercase tracking-wide text-gray-900 text-sm font-bold mb-2"
                                   htmlFor="firstName">
                                First Name
                            </label>
                            <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                   id="firstName" name="firstName" type="text" placeholder="Jane" /*onChange={handleChange}*/
                                   ref={register}/>
                            {errors.firstName && <p className="text-red-500 text-xs italic">{errors.firstName?.message}</p>}
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                            <label
                                className="block uppercase tracking-wide text-gray-900 text-sm font-bold mb-2"
                                htmlFor="lastName">
                                Last Name
                            </label>
                            <input
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="lastName" name="lastName" type="text" placeholder="Doe" /*onChange={handleChange}*/ ref={register}/>
                            {errors.lastName && <p className="text-red-500 text-xs italic">{errors.lastName?.message}</p>}
                        </div>
                    </div>

                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                            <label
                                className="block uppercase tracking-wide text-gray-900 text-sm font-bold mb-2"
                                htmlFor="email">
                                E-mail
                            </label>
                            <input
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="email" name="email" type="email" placeholder="Enter Your Email Address" /*onChange={handleChange}*/ ref={register}/>
                            <input type='text' name='honeypot' style={{ display: 'none' }} /*onChange={handleChange}*/ ref={register}/>
                            {errors.email && <p className="text-red-500 text-xs italic">{errors.email?.message}</p>}
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                            <label
                                className="block uppercase tracking-wide text-gray-900 text-sm font-bold mb-2"
                                htmlFor="message">
                                Message
                            </label>
                            <textarea
                                className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none"
                                id="message" name="message" /*onChange={handleChange}*/ ref={register} />
                            {errors.message && <p className="text-red-500 text-xs italic">{errors.message?.message}</p>}
                        </div>
                    </div>

                    {/* TODO: Make a toast alert displayed 3 seconds ?! */}
                    { response.type &&
                    <div className={`w-full border-l-4 rounded p-4 -mt-2 mb-6
                                    ${response.type === 'success' ?
                        "bg-green-100 border-green-500 text-green-700"
                        : "bg-red-100 border-red-500 text-red-700"}`} role="alert">
                        <p className="font-bold capitalize  mb-0">{response.type}</p>
                        <p className="mb-0">{response.message}</p>
                    </div>
                    }

                    <div className="relative">
                        <button type="submit" /*onClick={handleSubmit}*/
                                className="w-full py-4 text-lg font-bold text-center text-white transition duration-200
                                rounded-lg bg-green-500 hover:bg-green-600 ease focus:outline-none">
                            Send
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}