import React from "react";
// import Pricing from "../components/pricing";
import Contact from "../components/contact";
import Layout from "../components/layout";
import Hero from "../components/hero";
import ContentOverview from "../components/contentOverview";
import Features from "../components/features";
import UseCases from "../components/usecases";
// import logoAmazon from "../images/svg/logos/amazon.svg";
// import logoDisneyPlus from "../images/svg/logos/disneyplus.svg";
// import logoGoogle from "../images/svg/logos/google.svg";
// import logoHubspot from "../images/svg/logos/hubspot.svg";
// import logoIntuit from "../images/svg/logos/Intuit.svg";

// import logoSlack from "../images/svg/logos/slack.svg";
// import logoYoutube from "../images/svg/logos/youtube.svg";
import {useSiteMetadata} from "../hooks/use-site-metadata";
import SEO from "../components/seo";
// import FAQ from "../components/faq";
// import Testimonials from "../components/testimonials";

function TailwindIndexPage() {

    const siteMetadata = useSiteMetadata();

    return (

    <Layout>
        <SEO title={siteMetadata.title}/>

        <Hero/>

        <ContentOverview />

        {/*  Features  */}
        <Features />

        {/*Logos*/}
        {/*<section className="bg-white pt-7 pb-14">*/}
        {/*    <div className="container px-8 mx-auto sm:px-12 lg:px-20">*/}
        {/*        <h1 className="text-sm font-bold tracking-wide text-center text-gray-800 uppercase mb-7">*/}
        {/*            Trusted by top-leading companies.</h1>*/}
        {/*        <div className="flex grid items-center justify-center grid-cols-12 gap-y-8">*/}
        {/*            <div*/}
        {/*                className="flex items-center justify-center col-span-6 sm:col-span-4 md:col-span-3 xl:col-span-3">*/}
        {/*                /!*<img src="https://upload.wikimedia.org/wikipedia/commons/3/3e/Disney%2B_logo.svg" alt="Disney Plus"*/}
        {/*                     className="block object-contain h-12"/>*!/*/}
        {/*                 <img src={logoAmazon} alt="Amazon"*/}
        {/*                      className="block object-contain h-9"/>*/}
        {/*            </div>*/}
        {/*            <div*/}
        {/*                className="flex items-center justify-center col-span-6 sm:col-span-4 md:col-span-3 xl:col-span-3">*/}
        {/*                <img src={logoGoogle} alt="Google"*/}
        {/*                     className="block object-contain h-9"/>*/}
        {/*            </div>*/}
        {/*            <div*/}
        {/*                className="flex items-center justify-center col-span-6 sm:col-span-4 md:col-span-3 xl:col-span-3">*/}
        {/*                <img src={logoYoutube} alt="Youtube"*/}
        {/*                     className="block object-contain h-7 lg:h-8"/>*/}
        {/*            </div>*/}
        {/*            <div*/}
        {/*                className="flex items-center justify-center col-span-6 sm:col-span-4 md:col-span-6 xl:col-span-3">*/}
        {/*                <img src={logoIntuit} alt="Intuit"*/}
        {/*                     className="block object-contain h-9"/>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</section>*/}

        {/*Call to Action*/}

        <div className="py-8 border-t border-b border-yellow-200 bg-yellow-50">
            <div className="container mx-auto">
                <div
                    className="flex flex-col items-center content-center justify-center text-center lg:flex-row lg:text-left">
                    <img className="self-start w-auto h-24 px-4 mx-auto -mt-1 lg:mx-0" alt="Get Started"
                         src="https://cdn.devdojo.com/images/december2020/coffee-start.png" />
                    <div className="flex flex-col content-center px-4 mx-4 my-6">
                        <h4 className="pb-0 mb-0 text-lg font-semibold mb-md-0">
                            Sign up today and stay tuned !
                        </h4>
                        <p className="mb-0 text-gray-700">
                            We are working hard to bring this service to you.
                        </p>
                        <p className="mb-0 text-gray-700">
                            Subscribe and let us know that you have interests in what we want to offer.
                        </p>
                    </div>
                    <a data-formkit-toggle="445da8d0f0" href="https://tremendous-pioneer-7859.ck.page/445da8d0f0"
                       className="px-4 py-2 font-semibold text-white bg-green-500 border border-green-700 rounded shadow btn btn-secundary hover:bg-green-600 capitalize">
                        Join Waitlist
                    </a>
                </div>
            </div>
        </div>

        {/* Use cases */}
        <UseCases />

        {/*  Pricing  */}

        {/*Testimonials*/}
        {/*<Testimonials />*/}


        {/*Call to Action 2*/}
        {/*<section className="py-20 bg-gray-50">*/}
        {/*    <div*/}
        {/*        className="flex flex-col items-start justify-between px-12 mx-auto text-left md:px-8 lg:px-16 lg:flex-row max-w-7xl">*/}
        {/*        <div className="relative mb-6 space-y-4 md:space-y-1 lg:pr-20 lg:mb-0 lg:text-left lg:space-y-3">*/}
        {/*            <p className="flex items-center justify-start text-base font-semibold text-indigo-500">Take your*/}
        {/*                skills to the next level*/}
        {/*                <svg className="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24"*/}
        {/*                     xmlns="http://www.w3.org/2000/svg">*/}
        {/*                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"*/}
        {/*                          d="M14 5l7 7m0 0l-7 7m7-7H3"></path>*/}
        {/*                </svg>*/}
        {/*            </p>*/}
        {/*            <h2 className="font-serif text-4xl font-normal tracking-tight md:text-3xl md:leading-tight xl:text-4xl">Join*/}
        {/*                today and start crafting the next great idea</h2>*/}
        {/*            <p className="text-base font-medium text-gray-500 sm:text-sm xl:text-base">If you are serious about*/}
        {/*                taking your game to the next level, then you've got to signup today!</p>*/}

        {/*        </div>*/}
        {/*        <div className="flex w-full rounded-md shadow sm:w-auto xl:inline-flex">*/}
        {/*            <a href="#_"*/}
        {/*               className="inline-flex items-center justify-center w-full px-5 py-3 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md md:w-auto hover:bg-indigo-700">*/}
        {/*                Get Started Now*/}
        {/*            </a>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</section>*/}

        {/*FAQ*/}
        {/*<FAQ />*/}

        {/*Pricing*/}
        {/*<Pricing />*/}

        <Contact/>

    </Layout>
);
}

export default TailwindIndexPage;