import React from "react";

import {GiTeacher} from 'react-icons/gi';
import {IoIosPeople, } from 'react-icons/io';
import {IoLogoWechat} from 'react-icons/io5';
import {MdLeaderboard, MdPodcasts, MdSchool} from 'react-icons/md';

function UseCases() {

    return (
        <div className="box-border relative w-full font-sans leading-6 text-gray-700 bg-green-50 border-0 border-gray-200 border-solid">
            <div className="box-border flex flex-col items-center px-8 py-20 mx-auto leading-6 border-solid max-w-7xl xl:px-16 md:items-stretch md:justify-center md:py-24">
                <div className="relative pb-10">
                    <h5 className="w-full mx-0 mt-0 mb-4 font-sans font-bold text-center text-purple-700 border-0 border-gray-200">
                        Insights
                    </h5>
                    <h2 className="w-full m-0 font-sans text-4xl font-black leading-loose tracking-wide text-center text-gray-700 border-0 border-gray-200 sm:text-5xl">
                        Use cases
                    </h2>
                    <p className="w-full max-w-xl mx-0 mx-auto mt-4 mb-0 font-sans text-sm font-medium leading-relaxed text-center text-gray-500 border-0 border-gray-200 lg:text-lg md:text-base">
                        SaveMyWords' services can be essential for your industry, especially when you have a high volume of media.<br/>
                        Here are some use cases for which we can help improve your industry.
                    </p>
                </div>

                <div className="z-10 grid gap-5 md:grid-cols-6 lg:grid-cols-9">
                    {/* Feature 1 */}
                    <div className="col-span-3 font-sans text-gray-700 bg-yellow-100 rounded-3xl">

                        <div
                            className="box-border flex flex-col items-start h-full px-2 py-8 mx-4 leading-6 text-center border-solid sm:flex-row sm:items-start sm:text-left">
                            <div
                                className="flex-shrink-0 p-3 font-sans text-gray-700 border border-gray-700 rounded-full">
                                <MdLeaderboard className="leading-6 text-center text-gray-700 align-middle stroke-current w-7 h-7" />
                            </div>
                            <div
                                className="mt-4 font-sans text-left text-gray-700 border-0 border-gray-200 sm:mt-2 sm:ml-4">
                                <h6 className="box-border text-2xl font-bold leading-none tracking-wide text-left border-solid">Marketing</h6>
                                <p className="box-border mx-0 mt-1 mb-0 font-medium leading-loose text-gray-500 border-solid sm:mt-4">
                                    Transcribe your VSL and get your sales text ready-to-go.
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* Feature 2 */}
                    <div className="col-span-3 font-sans text-gray-700 bg-yellow-100 rounded-3xl">
                        <div
                            className="box-border flex flex-col items-start h-full px-2 py-8 mx-4 leading-6 text-center border-solid sm:flex-row sm:items-start sm:text-left">
                            <div
                                className="flex-shrink-0 p-3 font-sans text-gray-700 border border-gray-700 rounded-full">
                                <IoIosPeople className="leading-6 text-center text-gray-700 align-middle stroke-current w-7 h-7" />

                            </div>
                            <div
                                className="mt-4 font-sans text-left text-gray-700 border-0 border-gray-200 sm:mt-2 sm:ml-4">
                                <h6 className="box-border text-2xl font-bold leading-none tracking-wide text-left border-solid">Influencers</h6>
                                <p className="box-border mx-0 mt-1 mb-0 font-medium leading-loose text-gray-500 border-solid sm:mt-4">
                                    Increase your audience and SEO by adding captions to your videos.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* Feature 3*/}
                    <div className="col-span-3 font-sans text-gray-700 bg-yellow-100 rounded-3xl">
                        <div
                            className="box-border flex flex-col items-start h-full px-2 py-8 mx-4 leading-6 text-center border-solid sm:flex-row sm:items-start sm:text-left">
                            <div
                                className="flex-shrink-0 p-3 font-sans text-gray-700 border border-gray-700 rounded-full">
                                <MdPodcasts className="leading-6 text-center text-gray-700 align-middle stroke-current w-7 h-7"/>
                            </div>
                            <div
                                className="mt-4 font-sans text-left text-gray-700 border-0 border-gray-200 sm:mt-2 sm:ml-4">
                                <h6 className="box-border text-2xl font-bold leading-none tracking-wide text-left border-solid">Podcastors</h6>
                                <p className="box-border mx-0 mt-1 mb-0 font-medium leading-loose text-gray-500 border-solid sm:mt-4">
                                    Automatically transcribe your podcast.
                                    Add transcription as description to increase your SEO.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* Feature 4*/}
                    <div className="col-span-3 font-sans text-gray-700 bg-yellow-100 rounded-3xl">
                        <div
                            className="box-border flex flex-col items-start h-full px-2 py-8 mx-4 leading-6 text-center border-solid sm:flex-row sm:items-start sm:text-left">
                            <div
                                className="flex-shrink-0 p-3 font-sans text-gray-700 border border-gray-700 rounded-full">
                                <GiTeacher className="leading-6 text-center text-gray-700 align-middle stroke-current w-7 h-7"/>
                            </div>
                            <div
                                className="mt-4 font-sans text-left text-gray-700 border-0 border-gray-200 sm:mt-2 sm:ml-4">
                                <h6 className="box-border text-2xl font-bold leading-none tracking-wide text-left border-solid">Trainers</h6>
                                <p className="box-border mx-0 mt-1 mb-0 font-medium leading-loose text-gray-500 border-solid sm:mt-4">
                                    Give notes to your students, directly extracted from your video.
                                    They can then highlight and annoted them.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* Feature 5 */}
                    <div className="col-span-3 font-sans text-gray-700 bg-yellow-100 rounded-3xl">
                        <div
                            className="box-border flex flex-col items-start h-full px-2 py-8 mx-4 leading-6 text-center border-solid sm:flex-row sm:items-start sm:text-left">
                            <div
                                className="flex-shrink-0 p-3 font-sans text-gray-700 border border-gray-700 rounded-full">
                                <MdSchool className="leading-6 text-center text-gray-700 align-middle stroke-current w-7 h-7"/>
                            </div>
                            <div
                                className="mt-4 font-sans text-left text-gray-700 border-0 border-gray-200 sm:mt-2 sm:ml-4">
                                <h6 className="box-border text-2xl font-bold leading-none tracking-wide text-left border-solid">Students</h6>
                                <p className="box-border mx-0 mt-1 mb-0 font-medium leading-loose text-gray-500 border-solid sm:mt-4">
                                    Focus on understanding the course, not on taking notes.
                                    Record the professor vocal and transcribe it to get the course text.
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* Feature 6 */}
                    <div className="col-span-3 font-sans text-gray-700 border-0 bg-yellow-100 rounded-3xl">
                        <div
                            className="box-border flex flex-col items-start h-full px-2 py-8 mx-4 leading-6 text-center border-solid sm:flex-row sm:items-start sm:text-left">
                            <div
                                className="flex-shrink-0 p-3 font-sans text-gray-700 border border-gray-700 rounded-full">
                                <IoLogoWechat className="leading-6 text-center text-gray-700 align-middle stroke-current w-7 h-7"/>
                            </div>
                            <div
                                className="mt-4 font-sans text-left text-gray-700 border-0 border-gray-200 sm:mt-2 sm:ml-4">
                                <h6 className="box-border text-2xl font-bold leading-none tracking-wide text-left border-solid">Interviews</h6>
                                <p className="box-border mx-0 mt-1 mb-0 font-medium leading-loose text-gray-500 border-solid sm:mt-4">
                                    Transcribe your interview and get the text ready for review & publication.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default UseCases;